@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');

.footerWrapper {
    width: 100%;
    background-color: #fff;
    padding: 0 20px;
    border-top: 1px solid #d3d3d3;
}

.footerContainer {
    max-width: 1200px;
    margin: 0 auto;
    padding: 70px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.footerLogo {
    font-size: 30px;
    font-weight: 800;
    font-family: "Plus Jakarta Sans", sans-serif;
    margin-top: -5px;
}

.footerDesc {
    font-size: 14px;
    font-weight: 400;
    font-family: "Plus Jakarta Sans", sans-serif;
    color: #777777;
    text-align: justify;
}

.footerIconsSocial {}

.subscribeUs {
    font-size: 18px;
    font-family: "Lexend Deca", sans-serif;
    margin-top: 15px;

}

.footerPartTitle {
    font-size: 19px;
    font-weight: 500;
    font-family: "Lexend Deca", sans-serif;


}

.footerpart1 {
    width: 290px;
}

.footerpart2 {
    display: flex;
    flex-direction: column;
}

.footerpart2desc {
    display: flex;
    flex-direction: column;
    color: #848484;
    font-size: 14px;
    font-family: "Plus Jakarta Sans", sans-serif;
    gap: 5px;

}

.footerpart3 {
    display: flex;
    flex-direction: column;
}

.footerpart3desc {
    display: flex;
    flex-direction: column;
    color: #848484;
    font-size: 14px;
    font-family: "Plus Jakarta Sans", sans-serif;
    gap: 5px;

}

.footerpart4 {
    display: flex;
    flex-direction: column;
}

.footerpart4desc {
    display: flex;
    flex-direction: column;
    color: #848484;
    font-size: 14px;
    font-family: "Plus Jakarta Sans", sans-serif;
    gap: 5px;

}

.footerpart5 {
    display: flex;
    flex-direction: column;
}

.footerpart5desc {
    display: flex;
    flex-direction: column;
    color: #848484;
    font-size: 14px;
    font-family: "Plus Jakarta Sans", sans-serif;
    gap: 5px;

}

.footerpart6desc {
    display: flex;
    flex-direction: column;
    color: #848484;
    font-size: 14px;
    font-family: "Plus Jakarta Sans", sans-serif;
    gap: 5px;

}

.newsletterForm {
    display: flex;
    align-items: center;
    gap: 1px;
    margin-top: 20px;
    position: relative;
  }
  
  .newsletterInput {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius:35px;
    width: 100%; 
    max-width: 300px; 
    background-color: #EDF7FE;
  }
  
  .subscribeButton {
    padding: 15px 12px;
    background-color: #1C61E7;
    color: white;
    border: none;
    border-radius:20px;
    cursor: pointer;
    white-space: nowrap;
    position: absolute;
    right: 0%; 
  }
  
  .subscribeButton:hover {
    background-color: #0056b3;
  }
@media screen and (max-width: 768px) {
    .footerContainer {
        flex-direction: column;
        padding: 60px 20px;
        gap: 30px;
    }
    .subscribeButton{
        
    }
}

@media screen and (max-width: 768px) {
    .footerContainer {
        flex-direction: column;
        padding: 50px 20px;
        gap: 20px;
    }

    .newsletterForm {
        flex-direction: column; 
        align-items: stretch; 
    }

    .newsletterInput, .subscribeButton {
        width: 100%; 
        max-width: none; 
        border-radius: 20px;
    }

    .subscribeButton {
        margin-top: 20px; 
        right: 0; 
        top: 50px;
    }
}