@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap');
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}
.bg-\[\#f0f8ff\] {
background: #fff !important;
}

.amazonImage:hover {
  transform: scale(1.1);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-menu .dropdown-item {
  transition: background-color 0.3s, box-shadow 0.3s; /* Add a transition for smooth effect */
}

.dropdown-menu .dropdown-item:hover {
  background-color: #6285c5; /* Change to your desired hover background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow on hover */
}

.dropdown-menu h1 {
  font-size: 1.2rem; /* Adjust the font size as needed */
  font-weight: bold;
  color: #333; /* Change the text color to your preference */
  margin: 10px 0; /* Add margin for spacing */
  text-transform: uppercase; /* Change text case to uppercase if desired */
}

.dropdown-menu .col h1 {
  background-color: #047bf9; /* Background color for the section headers */
  color: #ead8d8; /* Text color for the section headers */
  padding: 10px; /* Adjust padding as needed */
  margin: 0; /* Remove margin to eliminate spacing between sections */
}

.bold-link {
  font-weight: bold;
}

.colored-link {
  color: #1d8bba; /* Replace with your desired color, e.g., a hexadecimal or RGB value */
}

.search-button {
  padding: 0.5rem 1rem; /* Adjust the padding values to control the button size */
  font-size: 15px; /* Adjust the font size as needed */
}

.navbar-text {
  font-size: 16.5px; /* Adjust the font size as needed */
}

.centered-placeholder::placeholder {
  text-align: center; /* Center the text horizontally */
  padding: 0; /* Remove any padding to center the text within the input box */
  font-size: 10.8px; /* Adjust the font size as needed */
}

/*Product Card Starts*/
.prd-outer {
  margin-bottom: 10px;
  display: flex;
}
.prd-img {
  width: 160px;
}
.card-body {
  display: flex;
  flex-direction: column;
}
.card-basic-info {
  display: flex;
  justify-content: space-between;
}
.card-title {
  width: 83%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: 17px;
  font-weight: 500;
}
.card-title-mbl {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  /* font-size: 17px; */
  font-weight: 500;
}
.navigte-cls:hover {
  color: rgb(115, 115, 209);
  cursor: pointer;
}
.card-price {
  color: #006400;
  font-weight: 500;
  font-size: 20px;
}
.card-basic-info-actions {
  display: flex;
  justify-content: space-around;
  background: #f0f8ff;
}
.ref-logo {
  padding: 8px 20px;
  background: #f0f8ff;
}
.ref-logo:hover {
  background: #dfdfdf;
}
/*Product Card Ends*/

/*Laptops Starts*/
.lp-outer {
  display: flex;
  padding: 10px 0px;
  width: 100%;
}
.lp-bar {
  margin-right: 20px;
}
.lp-inner {
  display: flex;
  flex-direction: column;
  max-width: 850px;
}
.lp-prd-count {
  font-size: 18px;
  font-weight: 400;
  padding: 10px;
}
.lp-prd-card-wrapper {
  padding: 10px;
  background-color: white;
}
/*Laptops Bar End*/

/*Product Start*/
.prdt-outer {
  background-color: #ffffff;
}
.prdt-main {
  width: 80%;
  margin: auto;
  background-color: white;
}
.prdt {
  display: flex;
  flex-direction: column;
}
.prdt-section-main {
  display: flex;
}
.prd-spec-tbl {
  display: flex;
  flex-direction: column;
  font-size: 0.9286rem;
  color: #888;
}
.prd-spec-tbl-row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #c3c5c8;
}
.prd-spec-tbl-row-head {
  border-bottom: 2px solid #ddd;
  border-top: 2px solid #ddd;
  font-weight: 600;
}
/* Apply styles to even child elements */
.prd-spec-tbl-row:nth-child(even) {
  background-color: #f9f9f9;
}
.prd-list > li:before {
  content: '';
  margin-right: 6px;
  opacity: 0.7;
  flex: 0 0 13px;
  height: 13px;
}

/* Apply styles to odd child elements */
.prd-spec-tbl-row:nth-child(odd) {
  background-color: #f1f3f6;
}
.prd-spec-tbl-col-1 {
  width: 35%;
  text-align: left;
  font-weight: 600;
}
.prd-spec-tbl-col-2 {
  width: 65%;
  text-align: left;
  display: flex;
  flex-direction: column;
}
.prdt-func-main {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0;
}

.prd-prics-card {
  margin-top: 10px;
  display: flex;
  background-color: #f1f3f6;
}
.prd-prics-card-inner {
  display: flex;
  flex-direction: column;
}
.prd-prices {
  color: #005ebd;
  font-weight: 600;
  font-size: 1.9287rem;
  line-height: 2.2859rem;
  cursor: pointer;
}
.pric-card {
  display: flex;
  flex-direction: column;
  color: #005ebd;
}
.prdt-section-info {
  display: flex;
  flex-direction: column;
}
.prdt-section-spec {
  background: #f1f3f6;
}
/*Product End*/

/*Popular Card Starts*/
.popular-card-main {
  border: 1px solid #eee;
  max-width: 100%;
}
.popular-card-main-two {
  border: 1px solid #eee;
  max-width: 100%;
  margin: 50px auto;
}
.popular-card-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 10px 10px;
}
.popular-card-head > h1 {
  font-size: 1.2858rem;
  font-weight: 500;
  margin: 0;
}
.popular-card-body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.popular-card-item {
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  width: 25%;
  border: 1px solid #eee;
}
.popular-card-image {
  height: 300px;
  overflow: hidden;
  margin: auto;
}
.popular-card-title {
  margin-top: 10px;

  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.popular-card-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.popular-card-price {
  font-size: 1.0358rem;
  font-weight: 500;
  color: darkgreen;
  margin-top: 2px;
}
.rating-container {
  width: 100px; 
}
.popular-card-icon {
  opacity: 0.3;
}
.popular-card-icon:hover {
  opacity: 1;
  cursor: pointer;
}
.popular-card-navigator {
  color: #047bf9;
  cursor: pointer;
}
.popular-card-navigator:hover {
  color: #0059ff;
  cursor: pointer;
}
/*Popular Card Ends*/

.prc-btn svg {
  fill: #5b6976;
}
.prc-btn:hover svg {
  fill: #3476b4;
}
/*Laptops Bar End*/

/* Add the following styles to handle hover effect */
.box:hover {
  background-color: #ddd;
  text-decoration: none;
  color: #333;
}

.box:hover img {
  width: 40px;
  height: 40px;
}

/**/
.nav-bar {
  display: block;
  text-align: center;
  font-size: 16px;
}

.ul-li {
  list-style: none;
  /* border-left: 2px solid #3ca0e7; */
  display: inline-block;
  position: relative;
  text-decoration: none;
  text-align: center;
}

.css-hip9hq-MuiPaper-root-MuiAppBar-root{
  background-color: #525252 !important;
}

.css-11yukd5-MuiTabs-indicator{
  background-color: #fff !important;
}
.li-a {
  color: grey;
  padding:4px 10px;
  font-size:17px;
  /* margin-right:40px; */

}

.li-a:hover {
  color: #006dba;
}

.li:hover {
  cursor: pointer;
}

.ul-li-ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding-left: 0;
  left: 0;
  display: none;
  background: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.ul-li-ul- {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding-left: 0;
  left: -450px;
  display: none;
  background: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.ul-li-ul-- {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding-left: 0;
  left: -640px;
  display: none;
  background: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.ul-li--ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding-left: 0;
  left: -300px;
  display: none;
  background: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.ul-li-ul_ {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  padding-left: 0;
  left: -200px;
  display: none;
  background: white;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.ul-li:hover > ul,
.ul-li-ul:hover {
  visibility: visible;
  opacity: 1;
  display: flex;
  min-width: 1000px;
  text-align: left;
  padding-top: 20px;
  box-shadow: 0px 3px 5px -1px #ccc;
}

.ul-li-:hover > ul,
.ul-li-ul:hover {
  visibility: visible;
  opacity: 1;
  display: flex;
  min-width: 300px;
  text-align: left;
  padding-top: 20px;
  box-shadow: 0px 3px 5px -1px #ccc;
}

.ul-li-ul-li {
  clear: both;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
  border-style: none;
  font-family: "Roboto";
}

.ul-li-ul-li-a:hover {
  padding-left: 10px;
  border-left: 2px solid #000000;
  transition: all 0.3s ease;
}
.ul-li-ul-li-a-a:hover {
  padding-left: 10px;
  border-left: 2px solid #121213;
  transition: all 0.3s ease;
  color: black !important;
}

.a {
  text-decoration: none;
  font-family: "Roboto";

}
.a:hover {
  color: #000000;
}

.ul-li-ul-li-a {
  transition: all 0.5s ease;
}
.ul-li-ul-li-a-a {
  transition: all 0.5s ease;
  color: black !important;
  font-family: "Roboto";

}

.filed-list {
  list-style: none;
}

.field-item::before {
  content: "\2022";
  color: #adafb5;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.snow-white-background {
  background-color: snow;
}

.price-filter-container {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 20px;
}

.price-range {
  cursor: pointer;
  padding: 5px;
  margin: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  border: 1px solid #ddd;
  border-radius: 5px;
  color: #fff;
}

.navbar-list {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.arrow-text {
  display: flex;
  align-items: center;
}

.arrow-text i {
  margin-right: 4px; 
}
.font-medium {
  font-weight: 500;
}
.news-desc {
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.light-blue-background {
  background-color: #f3f8f9;
}
.light-green-background {
  background-color: #f9fbf9; /* Replace with your desired color code */
}
.mobile-container {
  display: flex;
  justify-content: space-between;
}
.mobile-box {
  cursor: pointer;
  padding: 15px;
  margin: 5px;
  background-color: snow;
  border: 1px solid #ddd;
  border-radius: 25px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.custom-h1 {
  font-size: 30px;
  margin: 10px;
}
.custom-h2 {
  font-size: 16px;
  margin: 10px;
}
.custom-h3 {
  margin: 14px;
  font-size: 16px; 
  line-height: 1.8;
  font-weight: 300;
}

.cta-box p {
  margin-top: 5px; /* Add margin for separation between h1 and p elements */
  font-weight: bold; /* Make the text bold or add any other styles */
}

.prdt-func {
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-right: 5px;
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  color: #333333; 
  font-family: "Onest",sans-serif;
}
.prdt-func:hover {
  color: #2268f3;
}
.prdt-func:hover svg {
  fill: #081c46;
}
.prdt-func svg {
  fill: #1C61E7;
  margin-right: 6px;
}
.custom-box {
  background-color: #FFFAFA;
  width: calc(100% - 40px);
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
  border: 1px solid black;
}