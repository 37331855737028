@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,200;6..12,300;6..12,400;6..12,500&family=Onest:wght@100..900&family=Poppins:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;1,200&display=swap');

.navbarwrapper{
    width: 100%;
    background-color: #fff;
}

.navbarContainer{
    /* max-width: 1200px; */
    margin: 0 auto;
    
}

.navbarTopBar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    max-width: 1290px;
    margin: 0 auto;
}

.navbarLogo{
    font-size: 30px;
    font-weight: 800;
    font-family: "Plus Jakarta Sans", sans-serif;
}

.navbarTopSearchbar{
    background-color: #F5F5F7;
    display: flex;
    align-items: center;
    border-radius: 20px;
    width: 50%;
    position: relative;

}
  .search-box {
    margin-left: 5px;
    padding: 2px 5px 7px 10px;
    /* border: 1px solid #fffefe; */
    border-radius: 18px;
    height: 38px; 
    align-items: center;
    position: relative;
    background-color: #F5F5F7 !important;
    width: 100%;
  }

  .dropdownss {
    position: absolute;
    top: 100%; 
    left: 0;
    width: 100%;
    background-color: #F5F5F7; 
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); 
    z-index: 1000;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

  }
  .dropdownss-item img {
    width: 100px; /* Fixed width */
    height: 100px; /* Fixed height */
    object-fit: contain;
  }
  
  
  .dropdownss-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdownss-item:hover {
    background-color: #f8f8f8;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  .search-box::placeholder {
    font-size: 10px;
    text-align: left;
    width: 100%; 
    color: #878585
  }
  .search-icon {
    margin-left: 5px;
    color: #1C61E7;
    font-size: 20px;
    pointer-events: none;
  }
  .navbarTopLeftContent{
    display: flex;
    gap: 10px;
  }

  .navbarContact{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .navbarEmail{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .navbarContactUsDesc{
    line-height: 15px;
  }
  .navbarEmailDesc{
line-height: 15px;
  }

.navbarTextContact{
    font-size: 14px;
    font-family: "Onest", sans-serif;
    font-weight: 600;
}
.navbarTextContact1{
    font-size: 14px;
    color: #1C61E7;
    font-family: "Onest", sans-serif;
}

.navbarbottomBarWrapping{
  background-color: #E6EFFD;

}

.navbarBottomBar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px 15px;
    height: 70px;
    max-width: 1290px;
    margin: 0 auto;
}

.navbarLeftCont{
    display: flex;
    gap: 20px;
}

.navbarIcon{
    border-radius: 35px;
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: #fff;
    padding: 10px 10px;
    width: 250px;
    cursor: pointer;
    font-family: "Onest", sans-serif;
    font-weight: 600;
    position: relative;
    z-index: 100;

}
.navbarIcon svg {
    color: #fff;
  }
.navbarBottomText{
    align-items: center;
    display: flex;
    gap: 30px;
    font-family: "Onest", sans-serif;
    color: #333333;
    font-size: 14px;
}
.navbarBottomText Link{
    cursor: pointer;
}
.navbarDropdown {
    position: relative;
  }
  
.navbarDropdownContent {
    display: none;
    position: absolute;
    background-color: #E6EFFD;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
    border-radius: 10px;
    font-size: 14px;
}
.compareWrapper {
  position: relative;
}

.compareBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: Onest, sans-serif;
  font-size: 14px;
  font-weight: 600;
  gap: 5px;
}

.compareSubMenu {
  position: absolute;
  top: calc(100% + 10px);
  font-family: "Onest", sans-serif;
  color: #333333;
  font-size: 16px;  
  left: 0;
  z-index: 1000; /* Ensure it appears above other content */
  background-color: #E6EFFD;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s, top 0.3s;
}

.compareSubMenu.show {
  opacity: 1;
  visibility: visible;
  top: calc(100% + 5px);
}

.compareSubMenu a {
  display: block;
  padding: 5px 0;
  color: #333;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.compareSubMenu a:hover {
  background-color: #fff;
  color: #1C61E7;
  padding: 8px 0;
}
  
.navbarDropdown:hover .navbarDropdownContent {
    display: block;
}
  
.navbarDropdownContent a {
    color: #333333;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-family: "Onest", sans-serif;
}
  
.navbarDropdownContent a:hover {
    background-color: #f9f9f9;
    border-radius: 7px;
}

.bottomLeftContent{
    display: flex;
    font-family: "Onest", sans-serif;
    font-size: 14px;
    align-items: center;
    gap: 5px;
    font-weight: 600;
}

.submenu {
    position: absolute;
    top: 100%;
    left: 16px;
    top: 60px;
    width: 215px;
    background-color: #fff; 
    border: 1px solid #F8F8F8;
    border-radius: 5px;
    padding: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
  }
  
  .submenuItem {
    padding: 8px 0;
    font-size: 14px;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
    display: flex;
    align-items: center;
    gap: 15px;
    color: #111111;
    margin-bottom: 10px;
  }
  
  .submenuItem a {
    text-decoration: none;
    color: #333;
    font-size: 14px;
  }
  
  .submenuItem a:hover {
    color: #1C61E7;
  }
  .right-arrow.popular-brands {
    content: '>';
    margin-left: 49px;
    color: #888;
    font-size: 15px;
  }
  
  .right-arrow.prices {
    content: '>';
    margin-left: 55px;
    color: #888;
    font-size: 15px;
  }
  
  .right-arrow.features {
    content: '>';
    margin-left: 43px;
    color: #888;
    font-size: 15px;
  }
  .subsubmenuItem:hover span.right-arrow {
    color: #1C61E7;
  }
  .submenuItem.withBorder {
    border-bottom: 1px solid #e7e7e7;
    font-size: 14px;
    font-weight: 800;
    font-family: "Poppins", sans-serif;
  }


  .submenu .submenuItem {
    position: relative; 
  }
  
  .subsubmenu {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    width: 200px;
    background-color: white;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .submenuItem:hover .subsubmenu {
    display: block;
  }
  
  .subsubmenuItem {
    padding: 10px 15px;
    white-space: nowrap; 
  }
  
  .subsubmenuItem:hover {
    background-color: #f1f1f1;
  }

  @media screen and (max-width: 480px) {
    .navbarTopBar {
      padding: 10px;
    }
  
    .navbarTopSearchbar {
      width: 100%;
    }
  
    .navbarLogo {
      font-size: 24px;
    }
  
    .navbarTopLeftContent {
      flex-direction: column;
      gap: 10px;
    }
  
    .navbarContact,
    .navbarEmail {
      flex-direction: column; 
      gap: 5px;
      align-items: flex-start;
    }
  
    .navbarTextContact,
    .navbarTextContact1 {
      font-size: 12px; 
    }
  
    .navbarIcon {
      width: 100%;
      justify-content: space-between;
    }
  
    .navbarIcon p {
      display: none;
    }
  
    .navbarBottomBar {
      flex-direction: column;
      padding: 10px;
    }
  
    .navbarLeftCont {
      width: 100%;
      flex-direction: column; 
      gap: 10px;
    }
  
    .bottomLeftContent {
      width: 100%;
      justify-content: center;
      margin-top: 10px;
    }
  
    .navbarBottomText {
      flex-direction: column; 
      gap: 10px;
      text-align: center;
    }
  
    .navbarDropdownContent {
      left: 0;
      width: 100%;
    }
  
    .submenu,
    .subsubmenu {
      left: 0;
      width: 100%;
    }
  
    .submenuItem,
    .subsubmenuItem {
      width: 100%;
      justify-content: space-between;
    }
  }
  