/* src/components/NotFoundPage.css */
.not-found-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f8f8;
  color: #333;
}

.not-found-page h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
}

.not-found-page p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.not-found-page a {
  text-decoration: none;
  color: #007bff;
  font-size: 1.2rem;
}

.not-found-page a:hover {
  text-decoration: underline;
}
